<template>
  <div class="login-wrapper">
    <div class="login-img" />
    <div class="box-img">
      <img src="../../assets/images/box/logo.png" alt="" />
    </div>
    <div class='content'>
      <!--  注销账号form  :footer="null" -->
      <div class="logoutContentT">
        <div class="textR textBold">开发者：深圳易思智科技有限公司</div>
        <div class="textR textBold">版本更新日期：2023年12月25日</div>
        <div class="textR textBold">版本生效日期：2024年2月28日</div>
        <div class="textTwo">
          <div class="textBold mb-20">
            提示条款
          </div>

          <div>
            深圳易思智科技有限公司（注册地址：广东省深圳市南山区粤海街道滨海社区海天一路11、13、15号深圳市软件产业基地5栋426，以下简称“人脉宝盒”或“我们”）多年以来一直高度关注用户（以下简称“您”）的个人信息和隐私安全。我们将按照本政策处理您的个人信息，我们建议您完整地阅读本政策，以帮助您了解维护自己个人信息权和隐私权的方式。除实现人脉宝盒基本功能、服务所需的信息，和根据法律法规要求所必需的信息之外，您可以拒绝我们处理其他信息，但这可能导致我们无法提供对应功能、服务，我们将在隐私政策中逐项说明相关情况。
            <!--    todo 加粗  -->
            <span class="textBold">
            本政策中，有关处理您敏感个人信息的内容以及其他有关您个人信息权益的重要内容，我们已用加粗、加黑、斜体或下划线等形式提示，请您特别关注。当您在人脉宝盒App（以下称“人脉宝盒App”）注册，并点击同意《人脉宝盒隐私政策》时，代表您在知情和同意的情况下，自愿向我们提供您的个人信息。如果您不同意本政策内容，应立即停止使用人脉宝盒App。
            </span>
          </div>

          <div>本隐私政策不适用于其他第三方向您提供的服务，您在选择使用第三方服务前应充分了解第三方服务的产品功能及隐私保护政策，再选择是否使用相应功能或服务。</div>
          <div>如果您有任何疑问、意见或建议，可通过以下方式与我们联系：</div>
          <div>电子邮件：service@issmart.com.cn</div>
          <div>电话：0755-86526445</div>
          <div class='mt-20 textBold'>第一部分
            <!--          加粗  -->
            <span class="ml-20">定义</span>
          </div>
          <div>
            <span class="textBold">个人信息：</span>
            <span>是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。</span>
          </div>
          <div>
            <span class="textBold">敏感个人信息：</span>
            <span>
是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。            </span>
            <span class="textBold">在本政策中个人敏感信息或有关您个人信息权益的重要条款将会以加粗加黑字体予以标注。
            </span>
          </div>
          <div>
            <span class="textBold">个人信息的处理：</span>
            <span>
包括个人信息的收集、存储、使用、加工、传输、提供、公开、删除等。
            </span>
          </div>
          <div>
            <span class="textBold">去标识化：</span>
            <span>
是指个人信息经过处理，使其在不借助额外信息的情况下无法识别特定自然人的过程。
            </span>
          </div>
          <div>
            <span class="textBold">匿名化：</span>
            <span>
是指个人信息经过处理无法识别特定自然人且不能复原的过程。
            </span>
          </div>

          <div class="textBold mt-20">
            第二部分 隐私政策
          </div>
          <div>本政策将帮助您了解以下内容：</div>
          <div>一、我们收集、使用您个人信息的目的、范围、方式</div>
          <div>二、我们如何使用cookie及相关技术</div>
          <div>三、我们如何委托处理、向第三方提供、公开披露、共同处理、转移您的个人信息</div>
          <div>四、我们如何存储您的个人信息</div>
          <div>五、我们对您个人信息保护采取的安全措施</div>
          <div>六、您的权利</div>
          <div>七、未成年人/儿童个人信息保护条款</div>
          <div>八、其他重要内容</div>
          <div>九、隐私政策更新</div>
          <div>十、如何联系我们 </div>

          <div class="textBold mt-20">一、我们收集、使用您个人信息的目的、范围、方式</div>
          <div>我们提供的业务功能需要依赖部分信息才得以运行，因此，需要收集、使用您的某些个人信息。
            <span class="textBold"> 对于我们提供特定服务时所必须的个人信息，若您不同意提供，则您无法使用相应服务。为了优化为您提供的产品和服务功能，或向您提供某些增值服务，
            我们可能进一步向您收集特定个人信息，若您不同意提供，则无法使用该等优化或增值功能，但不影响其他基本服务或功能的使用。</span>
            <br>
            在提供相关服务或功能中，我们可能在取得您的单独同意的前提下处理您的相关敏感个人信息。我们会采取必要的安全措施保障您的敏感个人信息安全，
            保护您的自主决定权、公平待遇、财产安全和人身安全不受侵犯。<br>我们将在如下服务或功能中收集、使用您个人信息：</div>

          <div class='mt-20'><span class="textBold5">1.</span> 联系人管理<br>
            您可以在应用中对您的联系人进行管理，包括对通讯录联系人信息的读取、修改、增加、删除、设置分组和人脉圈等操作。
            您在使用该功能时，我们需要申请获取您的通讯录读写权限。在得到您的授权后，我们会读取通讯录联系人数据，并在相关功能中使用。<br>
            在得到您的授权后，我们会在您的联系人信息发生变化时，自动进行数据同步，将系统数据同步到应用中。
            <span class="textBold">
该过程可能会在后台进行。<br>
如果您未主动开启云端备份和同步功能，那么您的联系人信息将始终保留在您的设备中，我们不会以任何方式将您的联系人信息保存到服务端。<br>
如果您主动开启云端备份和同步功能，那么您的联系人信息将通过加密技术保存到服务端，且未经授权人员无法访问。
            </span>
          </div>
          <div> <span class="textBold5">2.</span> 联系历史管理<br>
            您可以在应用中对您的联系历史记录进行管理，包括电话、短信等信息。
            您在使用该功能时，我们需要申请获取您的短信、通话记录等数据的读取权限。
            在得到您的授权后，我们会读取联系人、短信、通话记录等数据，并在相关功能中使用。<br>
            在得到您的授权后，我们会在您的短信、通话记录等信息发生变化时，自动进行数据同步，将系统数据同步到应用中。 <span class="textBold">该过程可能会在后台进行。</span><br>
            如果您未曾主动进行云端备份或主动开启自动云端备份功能，那么您的短信、通话记录等数据将始终保留在您的设备中，我们不会以任何方式采集您的短信、通话记录等数据并发送到服务端。
          </div>
          <div>
            <span class="textBold5">3.</span>  短信发送<br>
            您在使用短信昵称群发功能时，我们需要申请<span class="textBold">联系人</span>读取权限、短信发送和读写权限。在得到您的授权后，我们才能为您提供短信发送服务。<br>
            您可能需要使用定时发送功能，在您设置定时发送任务时，我们需要申请联系人读取权限、短信发送和读写权限。在得到您的授权后，
            我们会按照您的任务设置，在后台定时为您提供短信发送服务。<br>
            即使我们已得到您的授权，在您未主动进行操作或设置定时任务的情况下，我们也不会以任何形式进行短信发送。
          </div>

          <div><span class="textBold5">4. </span> 消息推送<br>
            <span class="textBold5">4.1</span>我们可能会向您推送消息通知，为实现这一功能，我们可能会申请消息推送权限，并收集必要的日志信息。用于信息展示的日志信息包括：<br>
            - 您操作、使用的行为信息：点击、收藏、搜索、浏览等<br>
            - 您主动提供的反馈信息<br>
            - 地理位置信息：GPS信息<br>
            - 终端设备信息：设备MAC地址、设备ID、Android ID、GAID、OAID<br>
            <span class="textBold5">4.2 </span> <span class="textBold">GPS地理位置</span>是敏感个人信息，若您拒绝提供，我们将不会根据GPS信息向您推荐信息。<br>
            <span class="textBold5">4.3 </span>我们收集、使用的上述信息进行了去标识化处理，数据分析仅对应特定的、无法直接关联您身份的编码，不会与您的真实身份相关联。<br>
            <span class="textBold5">4.4</span> 如您拒绝使用推送服务，不会影响您对本服务其它功能的使用，您可以通知APP系统管理员来终止推送服务。
          </div>

          <div> <span class="textBold5"> 5. </span>
            <span class="textBold"></span>
            意见反馈<br>
            您主动进行意见反馈时，我们将收集您反馈的内容以及您使用的本服务类别、方式及相关操作信息，包括： <span class="textBold">所在位置、</span>网络信息、<span class="textBold">APP</span>
            版本信息、设备类型、设备厂家和型号、IP地址、设备操作系统信息以及其他与本服务相关的日志信息。
          </div>

          <div> <span class="textBold5"> 6. </span>
            拍照上传
            您使用某些特定模块进行拍照上传时，我们可能会请求开启您的 <span class="textBold">地理位置、</span>摄像头及相册（存储）权限并收集相关信息，
            包括：照片、所在位置、网络信息、设备类型、设备厂家和型号、客户端版本信息、 <span class="textBold">IP</span>地址、设备操作系统信息以及其他与本服务相关的日志信息。
            特别需要指出的是，即使经过您的授权，我们获得了 <span class="textBold">地理位置、摄像头</span>及相册权限，也不会在相关功能或服务关闭时而收集您的信息。

          </div>

          <div>
            <span class="textBold5"> 7. </span>
            <span class="textBold"></span>
            搜索<br>
            您可以通过搜索来找到您所需要的信息或服务。我们会保留您的搜索内容以方便您重复输入或为您展示与您搜索内容相关联的信息或服务。
            您使用搜索服务时，我们会收集您的搜索关键词信息、日志记录以便向您展现相关程度更高的搜索结果。搜索结果可能包括 <span class="textBold">APP</span>内信息
            和第三方服务信息，当您使用第三方服务时，您的信息可能会由第三方网站收集。为了提供高效的搜索服务，部分前述信息还会暂时
            存储在您的本地存储设备之中，并可向您展示搜索结果内容、搜索历史记录。<br>
            请您注意，您的搜索关键词信息无法单独识别您的身份，不属于您的个人信息，我们有权以任何的目的
            对其进行使用；只有当您的搜索关键词与您的其他信息相互结合使用并可以识别您的身份时，则在结合
            使用期间，我们会将您的搜索关键词信息作为您的个人信息，与您的搜索历史记录一同按照本隐私政策对其进行处理与保护。

          </div>

          <div>
            <span class="textBold5">  8. </span>
            <span class="textBold"></span>
            拨打电话<br>
            您可以在 <span class="textBold">APP</span> 内置的通讯录里面直接拨打某一联系人的电话，我们需要调用系统拨打电话的 <span class="textBold">API</span> 。<br>
            在 <span class="textBold">APP</span> 常用电话、联系人管理等功能中，
            将展示电话号码等联系方式，您可以直接点击这些联系方式拨打电话，此时我们需要调用系统拨打电话 <span class="textBold">API</span> 。

          </div>

          <div>
            <span class="textBold5"> 9. </span>
            <span class="textBold"></span>
            应用内版本更新<br>
            当我们发布新的应用版本后，您可以在应用内下载安装最新版本，我们需要申请手机存储的读写权限、手机软件安装列表权限、
            应用内安装权限，用于新版本的下载和安装。<br>
            即使我们已得到您的授权，在您未主动进行操作的情况下，我们也不会以任何形式进行软件的下载、安装或手机软件信息的采集。

          </div>

          <div>
            <span class="textBold5"> 10. </span>
            分享<br>
            您可以把   <span class="textBold">APP</span>内某些感兴趣的内容分享给您的社交好友，我们可能会调用第三方的SDK来实现分享功能。
          </div>


          <div>
            <span class="textBold5"> 11. </span>
            <span class="textBold"></span>
            定位<br>
            您可能需要在<span class="textBold">APP</span>中获取当前的位置信息，我们可能会请求定位权限，获取您的位置信息实现相关功能。

          </div>


          <div>
            <span class="textBold5">12. </span>
            <span class="textBold"></span>
            自启动<br>
            您在APP中使用的部分功可能需要<span class="textBold">APP</span>进行自启动，如定期自动数据备份、定时发送短信、
            事务提醒、通话后的记录提醒等功能，为实现该类功能，本<span class="textBold">APP</span>可能会监听相关系统事件。
            您可以在 <span class="textBold">APP</span> 中关闭该类功能。

          </div>


          <div>
            <span class="textBold5">13.</span>
            <span class="textBold"></span>
            运营与安全保障<br>
            <span class="textBold5">13.1 </span>为了保障软件服务的安全、运营的质量及效率，我们会收集您的所在位置、
            网络信息、 <span class="textBold">APP</span> 版本信息、设备类型、设备厂家和型号、设备序列号
            、 <span class="textBold">IP</span> 地址、设备操作系统信息网络接入方式、类型、状态、网络质量数据、操作、使用、服务日志。<br>

            <p>
              <span class="textBold5"> 13.2 </span>
              为了预防恶意程序、确保运营质量及效率，我们会收集安装的应用信息或正在运行的进程信息、应用程序的总体运行、
              使用情况与频率、应用崩溃情况、总体安装使用情况、性能数据、应用来源。<br>

              <span class="textBold5"> 13.3 </span>我们可能使用您的账号信息、设备信息、服务日志信息以及我们的关联方、合作方在获得您授权后或依法与我们共享的信息，用于判断账号安全、进行身份验证、检测及防范安全事件。
            </p>
            <span class="textBold5">14.</span>
            第三方<span class="textBold">SDK</span><br>
            在某些特定业务场景下，为了提高业务处理能力或其他方面考虑，我们可能会使用有能力的第三方
            <span class="textBold">SDK</span>来为您提供服务。这些<span class="textBold">SDK</span>可能会收集和使用您的个人信息，
            我们会在您授权同意后获取，我们对因您使用该等 <span class="textBold">SDK</span> 产生的风险和损失不承担任何责任，您应与该等<span class="textBold">SDK</span>的提供者协商解决。<br>

            第三方 <span class="textBold">SDK</span> 可能会收集和使用您的个人信息包括： <span class="textBold"> 位置、网络信息、APP</span>版本信息、设备类型、设备厂家和型号、设备序列号、
            <span class="textBold">IP地址、</span> 设备操作系统信息网络接入方式、类型、状态、网络质量数据、操作、使用、服务日志、传感器信息等。<br>
            我们调用以下 <span class="textBold">SDK：</span>
          </div>

          <div>
            <span class="textBold5">  14.1 </span>
            <span class="textBold"></span>

            微信分享 <span class="textBold">SDK</span> <br>
            当您使用信息分享到微信好友的服务时，我们需要调用微信分享 <span class="textBold">SDK</span>，该 <span class="textBold">SDK</span>
            不会收集您的个人信息，仅实现您对信息分享至微信好友的服务。
          </div>

          <div>
            <span class="textBold5"> 14.2  </span>
            <span class="textBold"></span>
            华为自然语言处理 <span class="textBold">SDK</span> <br>
            当您使用 <span class="textBold">AI</span>智能识别姓名、公司、职位，进行分词和对应填充时，
            我们需要调用华为自然语言处理<span class="textBold">SDK</span>，该<span class="textBold">SDK</span>不会收集您的个人信息，仅实现分词和对应填充服务。

          </div>

          <div>
            <span class="textBold5"> 14.3  </span>
            个推公共库 <span class="textBold">SDK</span>
            <br>
            当您使用 <span class="textBold">APP</span> 通知消息推送时，我们需要调用个推公共库 <span class="textBold">SDK</span> ，
            该SDK会收集您的 <span class="textBold">Android ID</span> 和 <span class="textBold">GAID</span> ，仅实现<span class="textBold">APP</span> 通知消息推送服务。

          </div>

          <div>
            <span class="textBold5"> 14.4  </span>
            <span class="textBold">DCloud</span>  开发通用工具库 <span class="textBold">SDK</span>
            <br>
            当您使用 <span class="textBold">APP</span> 通知消息推送时，我们需要调用 <span class="textBold">DCloud</span> 开发通用工具库 <span class="textBold">SDK</span> ，
            该<span class="textBold">SDK</span> 不会收集您的个人信息，仅实现<span class="textBold">APP</span>通知消息推送服务。
          </div>

          <div>
            <span class="textBold5"> 14.5  </span>
            数字天堂 <span class="textBold">SDK</span>
            <br>
            当您使用 <span class="textBold">APP</span> 通知消息推送时，我们需要调用数字天堂 <span class="textBold">SDK</span> ，该 <span class="textBold">SDK</span>
            会收集您的 <span class="textBold">OAID</span> ，仅实现<span class="textBold">APP</span>通知消息推送服务。
          </div>
          <div>
            <span class="textBold5"> 14.6  </span>
            com.alipay  <span class="textBold">CDK</span>
            <br>
            当您使用支付宝扣费时，我们需要调用com.alipay <span class="textBold">CDK</span> ，该 <span class="textBold">SDK</span> 不会收集您的个人信息，仅实现支付宝扣费服务。
          </div>
          <div>
            <span class="textBold5"> 14.7  </span>
            友盟统计分析  <span class="textBold">SDK</span>
            <br>
            当您使用人脉宝盒时，我们需要调用友盟统计分析 <span class="textBold">SDK</span> ，该 SDK会收集您的设备信息(<span class="textBold">IMEI/MAC/Android ID/IDFA/OAID/OpenUDID/GUID/SIM 卡 IMSI/ICCID</span>)、
            位置信息、网络信息，用于实现 <span class="textBold">APP</span> 运营统计与分析。
          </div>


          <div>
            <span class="textBold5">二、我们如何使用Cookie等技术 </span> <br>
            <span class="textBold">  </span>

            为使您获得更轻松的访问体验，您使用人脉宝盒  <span class="textBold">APP  </span>时，
            我们会在您的移动设备上发送名为 <span class="textBold">Cookies  </span> 的小数据文件以识别您的身份，以收集和存储您使用本服务时的信息。 <br>

            我们承诺，我们不会将<span class="textBold">Cookies  </span> 用于本隐私政策所述目的之外的任何用途，我们发送给您的<span class="textBold">Cookies  </span> 是唯一的，
            它只能被人脉宝盒APP服务器读取，您可根据自己的偏好管理或删除<span class="textBold">Cookies  </span>。  <br>
            我们向您发送<span class="textBold">Cookies  </span>目的是向您提供更友好、更安全的服务，主要实现以下功能或服务： <br>
            <span class="textBold5">1. </span>保障产品与服务的安全、高效运转 <br>

            我们可能会设置认证与保障安全性的 <span class="textBold">Cookies  </span> 或匿名标识符，使我们确认您是否安全登录服务，或者是否遇到盗用、
            欺诈及其他不法行为。这些技术还会帮助我们改进服务效率，提升登录和响应速度。<br>
            <span class="textBold5"> 2. </span> 帮助您获得更轻松的访问体验<br>
            使用此类技术可以帮助您省去重复您填写个人信息、输入搜索内容的步骤和流程（示例：记录搜索、表单填写）。<br>
          </div>

          <div>
            <span class="textBold5"> 三、我们如何存储和保护您的个人信息 </span> <br>

            <span class="textBold"></span>
            <span class="textBold5">  1. </span> 存储地点<br>

            我们在中华人民共和国境内收集和产生的个人信息将存储在中华人民共和国内。目前，我们不会将上述信息传输至境外，
            如果我们向境外传输，我们将会遵循相关国家规定或者征求您的同意。<br>
            <span class="textBold5">  2.  </span> 存储期限<br>

            我们仅在为提供本服务之目的所必需的期间内保留您的个人信息：您的意见反馈，在您未注销账号期间，我们会保留相关信息。超出必要期限后，
            我们将对您的个人信息进行删除或匿名化处理，但法律法规或监管机关另有规定的除外。<br>

          </div>

          <div>

            <span class="textBold"></span>
            <span class="textBold5">3.</span> 我们如何保护个人信息的安全<br>
            为保障您的信息安全，我们致力于使用各种安全技术及配套的管理体系等相关合理可行的手段来尽量降低您的个人信
            息被不当使用或被泄露、毁损、误用、丢失、非授权访问、非授权披露和更改的风险。
            <br>

            <span class="textBold5">3.1</span>
            数据安全收集方面，通过个人信息安全影响评估确认数据收集的合法性、正当性和必要性，识别并以清晰、准确的方式告知用户以征得同意，
            同时对用户的授权同意采集行为进行日志记录；采用敏感识别工具对手机的用户数据进行分类分级，
            针对不同级别的数据设置不同等级的安全策略；采用技术措施对收集或产生数据的来源方进行身份识别，确保数据来源的准确性和抗抵赖性；
            <br>

            <span class="textBold5">3.2 </span>
            数据安全传输方面，使用安全通道传输个人信息，并通过合适的加密算法进行安全加密、脱敏处理，确保数据传输过程中个人信息的秘密性和完整性；
            <br>

            <span class="textBold5"> 3.3 </span>
            数据安全存储方面，采用数据分类分级管理制度，针对数据分类分级结果采取不同的信息存储策略；
            采用数据分类分级管理制度，针对数据分类分级结果采取不同的信息存储策略；个人敏感信息
            需加密存储，确保数据安全使用规范能够落实到位；针对存储有个人信息进行备份与恢复，确保个人信息在存储使用过程中的完整性；
            <br>

            <span class="textBold5"> 3.4 </span>
            数据安全处理方面，依照使用场景和安全需求对个人信息进行脱敏处理，例如在前端展
            示个人敏感信息之前需在服务端完成脱敏处理；开发、测试环境严禁使用真实用户信息；
            实施严格的数据权限控制机制，采取多重身份认证、网络/数据隔离等技术措施，对处理个
            人信息的行为进行有效监控，避免数据被违规访问和未授权使用。
            <br>

            <span class="textBold5">3.5 </span>
            我们设立了相关的内控制度、对可能接触到您的信息的工作人员采取最小够用授权原则；
            对工作人员处理您的信息的行为进行系统监控，不断对工作人员培训相关法律法规及隐私安全准则和安全意识强化宣导。
            <br>

            <span class="textBold5"> 4. </span>
            您知悉并同意，您接入本服务所用的系统和通讯网络，有可能因为我们可控范围外的因素而出现问题。
            因此，我们强烈建议您采取积极措施保护您个人信息的安全，包括但不限于使用复杂密码、定期修改密码、
            不将自己的账号密码及相关个人信息透露给他人。请您务必妥善保管好您的登录名及其他身份要素。您在使用本服务时，
            我们会通过您的登录名及其他身份要素来识别您的身份。一旦您泄露了前述信息，您可能会蒙受损失，并可能产生对您不利的法律后果。
            如您发现本服务登录名/或其他身份要素可能 已经泄露时，请您立即和我们取得联系，以便我们及时采取相应措施以避免或降低相关损失。
            <br>

            <span class="textBold5"> 5. </span>
            您知悉并理解，我们将尽力确保您提供给我们的个人信息的安全性，但是尽管已经采取了上述合理有效措施，
            并已经遵守了相关法律规定要求的标准，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭
            尽所能加强安全措施，也不可能始终保证信息百分之百的安全，我们将尽力确保您提供给我们的个人信息的安全性。
            <br>

            <span class="textBold5"> 6. </span>
            我们会制定应急处理预案，并在发生用户信息安全事件时立即启动应急预案，努力阻止这些安全事件的影响和后果扩大。在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响，我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以人脉宝盒APP提示/推送通知、发送邮件/短消息等方式（我们将根据实际情况选择一种或多种方式）告知您，
            难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
            <br>

            <span class="textBold5">7. </span>
            您一旦离开人脉宝盒APP，浏览或使用其他网站、服务及内容资源，我们将没有能力和直接
            义务保护您在人脉宝盒APP之外的软件、网站提交的任何个人信息，无论您登陆、
            浏览或使用上述软件、网站是否基于人脉宝盒APP的链接或引导。
            <br>

            <span class="textBold5">四、我们如何使用您的个人信息 </span>
            <br>

            <span class="textBold5">1.  </span>
            为了遵守国家法律法规及监管要求，以及向您提供服务及提升服务质量，或保障您的行号或您所在机构的账户信息安全，我们会在以下情形中使用您的信息：
            <br>


            <span class="textBold5"> 1.1 </span>
            我们会根据本隐私政策的约定并未实现我们的产品或/服务功能对所收集的个人信息进行使用。
            <br>
            <span class="textBold5">  1.2  </span>
            为了使您知晓使用本服务的状态，我们会向您发送服务提醒。您可以通过手机系统设置中的通知设置，关闭服务提醒。也可以通过通知设置重新开启服务提醒。
            <br>
            <span class="textBold5">   1.3 </span>
            为了保障服务的稳定性与安全性，我们会将您的信息用于身份验证、安全防范、诈骗监测、预防或禁止非法活动、降低风险、存档和备份用途。
            <br>
            <span class="textBold5"> 1.4 </span>
            根据法律法规或监管要求向相关部门进行报告。
            <br>
            <span class="textBold5"> 1.5 </span>
            邀请您参与我们产品或服务有关的客户调研。
            <br>
            <span class="textBold5"> 1.5 </span>
            邀请您参与我们产品或服务有关的客户调研。
            <br>
            <span class="textBold5"> 1.6  </span>
            在收集您的个人信息后，我们在通过技术手段对您的信息数据进行匿名化处理后，有权在不经您同意的情况下直接使用，有权对用户数据库进行分析并予以商业化的利用。该等匿名化的信息将无法识别信息主体。
            <br>

            <span class="textBold5">  1.7 </span>
            我们会对我们的产品与/或服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示我们的产品与/或服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。
            <br>
            <span class="textBold5">  1.8 </span>
            当我们按照法律法规要求公开披露您的个人信息时，我们会采用包括内容替换、匿名化处理方式对您的信息进行脱敏，以保护您的信息安全。 
            <br>
            <span class="textBold5">   1.9 </span>
            当我们要将信息用于本政策未载明的其他用途时，会按照法律法规及国家标准的要求以确认协议、具体场景下的文案确认动作等形式再次征求您的同意。
            <br>

            <span class="textBold5">五、我们如何对外提供您的个人信息</span>
            <br>

            <span class="textBold5"> 1. </span>
            共享
            <br>
            <span class="textBold5"> 1.1 </span>
            共享原则
            <br>

            <span class="textBold5"> 1）</span>
            授权同意原则： 向我们的关联方、第三方共享您的个人信息，需经过您的授权同意，除非共享的个人信息是去标识化处理后的信息，
            且共享第三方无法重新识别此类信息的自然人主体。如果关联方、第三方使用信息的目的超越原授权同意范围，他们需要重新征得您的同意。
            <br>
            <span class="textBold5"> 2）</span> 合法正当与最小必要原则： 向关联方、第三方共享的数据必须具有合法正当目的，且共享的数据以达成目的必要为限。<br>
            <span class="textBold5">  3）</span> 安全审慎原则： 我们将审慎评估关联方、第三方数据使用共享信息的目的，
            对这些合作方的安全保障能力进行综合评估，并要求其遵循法律及合作协议。我们可能会对合作方获取信息的软件工具开发包 <span class="textBold">（SDK）</span> 、
            应用程序接口 <span class="textBold">（API）</span> 进行安全监测，以保护数据安全。
            <br>


            <span class="textBold5"> 1.2 </span> 业务共享 <br>
            我们承诺对您的信息进行保密。除法律法规及监管部门另有规定外，我们仅在以下情形中与第三方共享您的信息，
            第三方包括我们的关联公司、合作金融机构以及其他合作伙伴。在将信息提供给第三方前，
            我们将尽商业上合理的努力评估该第三方收集信息的合法性、正当性、必要性。我们会与第三方签订相关法律文件
            并要求第三方处理您的个人信息时遵守法律法规，要求第三方对您的信息采取保护措施。
            <br>
            <span class="textBold5"> （1）</span> 某些服务可能由第三方提供或由我们与第三方共同提供，因此，只有共享您的信息，才能提供您需要的产品或服务。
            <br>
            <span class="textBold5"> （2）</span>事先获得您明确同意的情况下，我们会在法律法规允许且不违背公序良俗的范围内，依据您的授权范围与第三方共享您的信息。
            <br>


            <span class="textBold5"> 1.3 </span> 意见反馈及投诉处理
            <br>
            <span class="textBold5"> （1）</span>
            为确保您的意见反馈得到及时处理，在您提交意见反馈后，我们将向您的客户经理提供您的账号对应的 <span class="textBold">姓名、手机号、所在机构和意见反馈内容；</span>
            <br>
            <span class="textBold5"> （2）</span>当您投诉本服务或本服务相关业务时，为了保护您及他人的合法权益，我们可能会将您的姓名及有效证件号码、联系方式、投诉相关内容提供给消费者权益保护部门及监管机关，以便及时解决投诉纠纷，但法律法规明确禁止提供的除外；
            <br>


            <span class="textBold5">  1.4 </span> 实现安全与分析统计
            <br>
            <span class="textBold5">（1）</span> 分析产品使用情况：为分析我们服务的使用情况，提升用户使用的体验，可能会与关联方或第三方共享产品来源与使用
            情况（崩溃、闪退、使用时长等）的去标识化数据，这些数据难以与其他信息结合识别您的个人身份；
            <br>
            <span class="textBold5"> （2）</span> 学术研究与科研：为提升相关领域的科研能力，促进科技发展水平，我们在确保数据安全与目的正当的前提下，
            可能会与科研院所、高校等机构共享去标识化或匿名化的数据。
            <br>

            <span class="textBold5"> 1.5 </span>
            实名认证
            <br>
            为准确核实您的身份信息，以便为您提供服务，在获得您授权的前提下，需要将您的 <span class="textBold">证件信息、设备信息、IP地址、GPS等信息</span>
            提供给中国人民银行、公安部及其他第三方合作机构进行身份验证。
            <br>


            <span class="textBold5">2. </span>
            转让
            <br>
            我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
            <br>
            <span class="textBold5">2.1</span>事先获得您的明确同意；
            <br>
            <span class="textBold5">2.1</span>2.2根据法律法规或强制性的行政或司法要求；
            <br>
            <span class="textBold5">2.1</span>2.3在涉及资产转让、收购、兼并、重组或破产清算时，如涉及到个人信息转让，我们会向您告知有关情况，并要求新的持有您
            个人信息的公司、组织继续受本政策的约束。如变更个人信息使用目的时，我们将要求该公司、组织重新取得您的明确同意。
            <br>
            <span class="textBold5"> 3. </span>披露
            <br>
            我们不会公开披露您的信息，除非遵循国家法律法规规定或者获得您的同意。我们仅会在以下情况下且采取符合业界标
            准的安全防护措施的前提下，才会披露您的个人信息：
            <br>
            <span class="textBold5">3.1</span>在您明确同意的披露方式下披露您所指定的个人信息，我们会向您告知披露的目的，披露信息的类型及可能涉及的敏感信息；
            <br>
            <span class="textBold5">3.2</span> 根据法律、法规的要求，强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型
            和披露方式披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具预支相应的法律
            文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都将
            进行慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。
            <br>
            <span class="textBold5"> 4. </span>委托处理
            <br>
            为了提升信息处理效率，降低信息处理成本，或提高信息处理准确性，我们可能会委托有能力的我们的关联公司或其他的专业机构代表我们来
            处理用户信息，但我们会通过书面协议、现场审计等方式要求受托公司遵守严格的保密义务及采取有效的保密措施，禁止其将这些信息用于未经
            您授权的用途，并在委托关系解除时，要求受托公司不再保存个人信息，法律法规或监管部门另有规定的除外。
            <br>
            <span class="textBold5">5. </span> 根据相关法律法规及国家标准，在以下情形中我们可能会依法共享、转让、披露您的个人信息无需征得您的同意：
            <br>
            <span class="textBold5"> 5.1 </span>与国家安全、国防安全直接相关的；
            <br>
            <span class="textBold5"> 5.2 </span>与公共安全、公共卫生、重大公共利益直接相关的；
            <br>
            <span class="textBold5">5.3 </span>与犯罪侦查、起诉、深谙和判决执行等直接相关的；
            <br>
            <span class="textBold5">5.4  </span> 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；
            <br>
            <span class="textBold5"> 5.5 </span>所收集的信息是您自行向社会公开的或者是从合法公开的渠道中收集到的；
            <br>
            <span class="textBold5"> 5.6 </span>法律法规规定的其他情形。
            <br>
            <br>
            <span class="textBold">六、您如何访问和管理您的个人信息</span>
            <br>
            我们非常重视您对个人信息的关注，并尽全力保护您对于您个人信息的查询、访问、更正、删除
            以及撤回同意授权、注销账号的权利，以便您拥有充分的能力保障您的隐私和安全。您的权利包括：
            <br>
            <span class="textBold5"> 1. </span> 访问、更正和删除您的个人信息
            <br>
            <span class="textBold5"> 1.1 </span> 除法律法规规定外，您有权利随时登录人脉宝盒APP并通过
            “我的-个人中心”页面访问您的手机号、登录名等信息。
            <br>
            <span class="textBold5"> 1.2 </span>  您有权利联系您的客户经理，在您或您所在的机构通过线下方式完成全部变更手续后进行信息更正。
            <br>
            <span class="textBold5"> 1.3 </span> 在以下情形，您可以向我们提出删除个人信息的请求：
            <br>
            <span class="textBold5">1) </span> 如果我们处理个人信息的行为违反法律法规；
            <br>
            <span class="textBold5">2)  </span> 如果我们收集、使用您的个人信息却未征得您的同意；
            <br>
            <span class="textBold5"> 3) </span> 如果我们处理个人信息的行为违反了与您的约定；
            <br>
            <span class="textBold5">  4)  </span>如果我们终止服务及运营。
            <br>
            <span class="textBold5">  2. </span> 查询访问、更改、删除您的搜索历史记录
            <br>
            <span class="textBold5"> 2.1 </span> 点击搜索栏—删除搜索“历史记录”；
            <br>
            <span class="textBold5">  2.2 </span> 您可以通过点击“系统设置”—点击“清理缓存”。
            <br>

            <span class="textBold5">3.</span>改变您授权同意的范围或撤回您的授权
            <br> <span class="textBold">精确地理位置、摄像头、麦克风、相册（存储）权限，</span> 均不会默认开启，只有经过您的明示授权才会在为实现特定功能或服务时使用，
            您也可以撤回授权。您可以通过关闭摄像头、麦克风、相册权限、<span class="textBold">GPS</span>地理位置等功能改变同意范围或撤回您的授权。撤回授权后我们将不再收集与
            这些权限相关信息。您也可以通过注销账号的方式，撤回我们继续收集您个人信息的全部授权。<br>
            请您理解，每个业务功能需要一些基本的个人信息才能得以完成，当您撤回同意或授权后我们无法继续为您提供撤回同意或授权所对应的服务，
            也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。
            <br>




            <span class="textBold5">4. </span> 冻结
            如您或您所在的机构注册信息发生变更，公司将冻结相应的账号，但不会影响此前基于您的授权而开展的个人信息处理。
            <br>
            <span class="textBold5">5. </span>注销
            您可以通过线上实名注销账号的方式完成人脉宝盒 <span class="textBold">APP</span> 账号注销，您该账号内的所有信息将被清空，我们将不会再收集、
            使用或对外提供与该账号相关的个人信息，但您在使用人脉宝盒 <span class="textBold">APP</span> 服务期间提供或产生的信息我们仍需按照监管要求的时间进行保存，
            且在法定时间内监管机关仍有权依法查询。
            线上实名注销账号将在 <span class="textBold">15</span> 个工作日内完成。
            <br>
            <span class="textBold5">6.  </span>响应您的请求
            特别提示您注意，出于安全性和身份识别（如号码申诉服务）的考虑，您可能无法自主修改注册时提交的某些初始注册信息。
            如您确有需要访问、更正或删除您在使用本服务时所产生的相关个人信息，或您认为您的个人信息权利可能受到侵害，或发现线索表明本
            服务存在任何违反法律法规或与您关于个人信息的收集或使用的约定，您可根据本政策中的联系方式与我们取得联系。为了保障安全，
            我们可能需要您提供书面请求，或以其他方式证明您的身份，我们将在收到您反馈并验证您的身份后答复您的请求。对于您合理的请求，
            我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定的成本费用。对于那些无端重复、需要过多技术手段
            （例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，
            我们可能会予以拒绝。
            <br>

            <span class="textBold5"> 7. </span>征得授权同意的例外
            <br>
            以下情形中，个人信息控制者收集、使用个人信息不必征得个人信息主体的授权同意：
            <br>
            <span class="textBold5">7.1  </span>与个人信息控制者履行法律法规规定的义务相关的；
            <br>
            <span class="textBold5">7.2  </span>与国家安全、国防安全直接相关的；
            <br>
            <span class="textBold5">7.3  </span>与公共安全、公共卫生、重大公共利益直接相关的；
            <br>
            <span class="textBold5">7.4  </span>与刑事侦查、起诉、审判和判决执行等直接相关的；
            <br>
            <span class="textBold5"> 7.5 </span>出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；
            <br>
            <span class="textBold5">7.6 </span>所涉及的个人信息是个人信息主体自行向社会公众公开的；
            <br>
            <span class="textBold5">7.6  </span>根据个人信息主体要求签订和履行合同所必需的；
            <br>
            <span class="textBold5">7.8  </span>从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
            <br>
            <span class="textBold5">7.9  </span>维护所提供产品或服务的安全稳定运行所必需的，如发现、处置产品或服务的故障；
            <br>
            <span class="textBold5">7.10  </span>个人信息控制者为新闻单位，且其开展合法的新闻报道所必需的；
            <br>
            <span class="textBold5">7.11  </span>有充分证据表明您存在主观恶意或滥用权利的；
            <br>
            <span class="textBold5">7.12  </span>涉及商业秘密的。
            <br>



            <span class="textBold5">8. </span> 访问隐私政策
            <br>
            <span class="textBold5">8.1 </span>您可以在登录页面，或者在登录人脉宝盒 <span class="textBold">APP</span> 通过“我的—更多-关于-隐私政策”查看本政策的全部内容。
            <br>
            <span class="textBold5">8.2 </span>请您了解，本政策中所述的“人脉宝盒”及相关服务可能会根据您所使用的手机型号、
            系统版本、软件应用程序版本、移动客户端等因素而有所不同。最终的产品和服务以您所使用的“人脉宝盒” <span class="textBold">APP</span> 及相关服务为准。
            <br>
            <br>
            <span class="textBold">七、对第三方责任的声明</span>
            <br>
            请您注意，通过我们接入的第三方服务（例如：人脉宝盒<span class="textBold5"> APP </span>中第三方提供的应用）和由人脉宝盒<span class="textBold5"> APP </span>接收您的个人信息的
            第三方可能有自己的隐私保护政策；当您查看第三方创建的网页或使用第三方开发的应用程序时，这些第三方可能会放置他们
            自己的<span class="textBold5"> Cookie </span>或像素标签，这些 <span class="textBold5"> Cookie </span> 或像素标签不受我们的控制，且它们的使用不受本政策的约束。我们会尽商业上的合理努力去
            要求这些主体对您的个人信息采取保护措施，但我们无法保证这些主体对您的个人信息采取保护措施，我们对该等第三方收集、存储、使用和处理您
            的个人信息行为不承担任何责任。请您与他们直接联系以获得关于他们的隐私权政策的详细情况，在阅读并同意第三方的用户协议和隐私政策后使用该等服
            务和产品。如您不同意该等用户协议或隐私政策、或您发现这些第三方创建的网页或第三方开发的应用程序存在风险时，建议您终止相关操作以保护您的合法权益。
            <br>

            <br>
            <span class="textBold">八、未成年人/儿童个人信息保护条款</span>

            我们非常重视对 <span class="textBold5">未成年人/儿童 </span>个人信息的保护，若您是未满十四周岁的未成年人，建议您和您的监护人仔细阅读本政策及《人脉宝盒隐私政策》，
            并在征得您的监护人同意的前提下使用我们的服务或向我们提供信息。如果我们发现自己在未事先获得可证实的父母或监护人同意的情况下
            收集了未成年人/儿童的个人信息，则会暂停提供相关服务并设法尽快删除相关数据。待获得您的父母或监护人同意的情况下，我们会重新提供服务。
            若您是未成年人的监护人，当您对您所监护的未成年人的个人信息有相关疑问时，请通过本隐私政策公示的联系方式与我们联系。
            <br>



            <br>
            <span class="textBold">九、其他重要内容</span>
            <br>
            <span class="textBold5">1、 </span>
            若涉及到跨境业务，需要向中华人民共和国境外提供您的个人信息的，我们会按照中华人民共和国法律、行政法规和相关监管部
            门的规定执行，告知您境外接收方的名称或者姓名、联系方式、个人信息的种类、处理目的、处理方式以及您向境外接收方行使本法规定
            权利的方式和程序等，并取得您的单独同意。我们保障境外接收方处理个人信息的活动达到在中华人民共和国境内足够同等的保护。
            <br>
            <span class="textBold5"> 2、</span>在以下情形下，我们可能不会向您告知我们处理您的个人信息：
            <br>
            <span class="textBold5">（1）</span>紧急情况下为保护自然人的生命健康和财产安全无法及时向您告知的（但在紧急情况消除后我们会及时向您告知）；
            <br>
            <span class="textBold5">（2）</span>有法律、行政法规规定应当保密或者不需要告知您的。
            <br>
            <span class="textBold5"> 3、</span>根据相关法律法规，以下情形中，我们处理您的信息无需事先征得您的授权同意：
            <br>
            <span class="textBold5">（1）</span>为订立、履行用户与我们之间的合同所必需；
            <br>
            <span class="textBold5">（2）</span>为履行法定职责或者法定义务所必需；
            <br>
            <span class="textBold5">（3）</span>为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；
            <br>
            <span class="textBold5">（4）</span>为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；
            <br>
            <span class="textBold5">（5）</span>依照《个人信息保护法》及其他规定在合理的范围内处理用户自行公开或者其他已合法公开的个人信息，但用户明确拒绝的除外；
            <br>
            <span class="textBold5">（6）</span>法律、行政法规规定的其他情形。


            <br>
            <br>

            <span class="textBold"> 十、本政策的适用及更新</span>
            <br>
            为了提供更好的服务，我们可能会根据服务的更新情况及法律法规、监管政策的相关要求适时修改、
            变更本政策的部分条款，该等修改、变更构成本政策的一部分。我们会在本页面上发布对本政策所做的任何修改、
            变更并向您做出显著的通知。对于重大变更，我们还会提供更为显著的通知（包括对于某些服务，我们会通过应用推送、应
            用内消息、短信等方式，告知您本政策的具体变更内容）。
            <br>
            本政策所指的重大变更包括但不限于：
            <br>
            <span class="textBold5">（1）</span>个人信息的处理目的、处理方式和处理的个人信息种类发生变更、所有权结构、组织架构等方面发生重大变化；
            <br>
            <span class="textBold5">（2）</span>业务调整、破产并购等引起的所有者变更等；
            <br>
            <span class="textBold5">（3）</span>个人信息向第三方提供、转让或公开披露的主要对象发生变化；
            <br>
            <span class="textBold5">（4）</span>个人信息处理方面的权利及其行使方式发生重大变化；
            <br>
            <span class="textBold5">（5）</span>负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时等。
            <br>
            如果您继续使用人脉宝盒 <span class="textBold">APP</span> ，视为您同意修订、变更后的隐私政策。如果您不同意我们做出的任何修改、变更，
            应立即停止使用人脉宝盒 <span class="textBold">APP</span> 。 <br>
            我们鼓励您在每次登录时都认真查阅并仔细浏览我们本政策的全部内容，查阅路径为：我的-更多-关于-《人脉宝盒隐私政策》。
            <br>


            <div>
              <span class="textBold"> 十一、如何联系我们</span>

              <br>
              如您对本隐私政策或您个人信息的相关事宜或者投诉、举报、有任何问题、意见或建议，请通过以下方式与我们联系，我们将在15个工作日内作出答复：
              <br>
              请发送电子邮件至：service@issmart.com.cn
              <br>
              或者拨打客服电话：0755-86526445
              <br>
              我们有个人信息保护负责人，负责对我们处理用户信息的活动以及我们采取的保护措施进行监督，个人信息保护负责人的联系方式：<span class="textBold5"> david.hu@issmart.com.cn </span>
              <br>

            </div>
          </div>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'UserAgreement'
};
</script>

<style scoped lang='less'>
.login-wrapper {
  position: relative;
  display: flex;
  height: 100vh;
  width: 100%;
  .box-img {
    position: absolute;
    top: 52px;
    left: 52px;
  }

  .login-img {
    min-width: 960px;
    flex: 3;
    background: url('../../assets/images/box/back-img.png') no-repeat center;
    background-size: 100% 100%;
    background-position: right;
  }

  .content {
    min-width: 320px;
    position: absolute;
    top: 5%;
    //transform: translateY(-50%);
    right: 16%;
    max-width: 68%;
    max-height: 90%;
    padding: 54px 40px 40px;
    background-color: #ffffffcc;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    border: 2px solid #ffffff;
    box-sizing: border-box;
    overflow: auto;
  }


}




</style>